:root {
	--primary-color: #00415A;
	--secondary-color: #EEEEEE;
	--tertiary-color: #C4D8E2;
	--quaternary-color: #A14A76;
	--quinary-color: #56351E;
	--senary-color: #28965A;
	--septary-color: #6290C3;
	--dark-color: #222222;
	--warning-color: rgb(188, 6, 6);
	--warning-text-color: white;

	--link-color: #0CCE6B;
	--link-hover-color: var(--text-color);

	/* Cards */
	--odd-card-bg: #69257E;
	--odd-card-link: #66CDDD;
	--even-card-bg: #1D879F;
	--even-card-link: #DAD207;

	--background-color: var(--primary-color);
	--text-color: var(--secondary-color);

	--scrollbar-bg: var(--text-color);
	--scrollbar-thumb: var(--septary-color);
	--scrollbar-thumb-hover: var(--senary-color);

	/* --font: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
	--font: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

@media (prefers-color-scheme: dark) {
	:root {
		--primary-color: #052D3D;
		--secondary-color: #EDEDED;
		--tertiary-color: #AABFC9;
		--quaternary-color: #822453;
		--quinary-color: #502D15;
		--senary-color: #167E45;
		--septary-color: #4674A8;
		--dark-color: #222222;
		--warning-color: rgb(153, 7, 7);
		--warning-text-color: white;

		--link-color: #09ac58;

		--scrollbar-bg: var(--dark-color);

		/* Cards */
		--odd-card-bg: #5b1e6d;
		--odd-card-link: #66CDDD;
		--even-card-bg: #167489;
		--even-card-link: #FAF341;
	}
}

* {
	box-sizing: border-box;
}

::selection {
	background-color: var(--text-color);
	color: var(--background-color);
}
a::selection {
	background-color: var(--link-color);
	color: var(--dark-color);
}
a:hover::selection {
	color: var(--link-hover-color);
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: var(--scrollbar-bg);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-thumb);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: var(--scrollbar-thumb-hover);
}

body, html {
	background-color: var(--background-color);
	color: var(--text-color);
	height: 100%;
	padding: 0;
	margin: 0;
	font-family: var(--font);
}

body {
	display: flex;
	flex-direction: column;
}

main {
	background-image: url(resources/Clements.jpg);
	background-size: cover;
	background-attachment: fixed;
	display: flex;
	justify-content: center;
}

@media (max-width: 1000px) {
	main {
		flex-direction: column;
	}
	.sidebar {
		width: 100%;
		margin: 0;
	}
	.maincontent {
		width: 100%;
		overflow-x: hidden;
	}
	#analytics_ignore {
		margin: 0 !important;
	}
	header {
		flex-wrap: wrap;
		height: 55px;
	}
	header[data-expanded="true"] {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 999;
		background-color: var(--primary-color);
		align-items: start;
		box-shadow: var(--tertiary-color) 5px 5px 15px;
	}
	header[data-expanded="true"] > .header_right {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-left: 10px;
		margin-right: 10px;
	}
	header[data-expanded="false"] > .header_right {
		display: none;
	}
	header[data-expanded="true"] .header_link {
		margin: 15px;
		width: 100%;
		height: 50px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#volunteer_request_iframe {
		display: none;
	}
	.modal {
		width: 100%;
		height: 100%;
	}
	.header_left {
		width: 100%;
	}
	.warning_banner[data-show="true"] {
		min-height: 100px;
	}
}

@media (min-width: 1000px) {
	.maincontent {
		width: 50%;
	}
	header {
		height: 80px;
	}
	.modal {
		width: 	500px;
		height: 500px;
	}
	.menu_icon {
		display: none;
	}
	.warning_banner[data-show="true"] {
		min-height: 80px;
	}	
	.maincontent:not(.has_sidebar) {
		width: 50%;
	}
	.maincontent.has_sidebar {
		width: 45%;
	}
	.sidebar {
		width: 20%;
		margin-left: 15px;
	}
}

.maincontent {
	opacity: 0.92;
	background-color: var(--primary-color);
	text-align: center;
	padding: 20px;
	line-height: 25px;
	min-height: 80vh;
}

.content404 {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.center {
	text-align: center;
}

.maincontent p {
	margin-top: 30px;
	margin-bottom: 30px;
}

.warning_banner[data-show="false"] { display: none; }

.warning_banner[data-show="true"] {
	--accent: var(--warning-color);
	--accent-text-color: var(--warning-text-color);
	width: 100%;
	background-color: var(--accent);
	color: var(--accent-text-color);
	display: flex;
	justify-content: center;
	padding: 5px;
	text-align: center;
	overflow-y: auto;
}

.warning_banner[data-positive="true"] {
	--accent: var(--senary-color);
	--accent-text-color: var(--text-color);
}

.warning_banner[data-show="true"] *::selection {
	color: var(--accent);
	background-color: var(--accent-text-color);
}

.sidebar {
	opacity: 0.92;
	background-color: var(--primary-color);
	padding: 10px;
	overflow: auto;
}
.sidebar:has(.fingerprint-spinner) {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
.sidebar > .fingerprint-spinner {
	margin-top: 30px;
}

.sidebar_element {
	border: 2px solid var(--quaternary-color);
	border-radius: 5px;
	padding: 5px;
	margin: 8px;
	margin-top: 12px;
	margin-bottom: 12px;
	text-align: center;
}

/* .structured_sidebar_element_item */
.sse_item {
	text-align: center;
	margin-top: 2px;
	margin-bottom: 2px;
}

.structured_sidebar_element:nth-child(odd) {
	background-color: var(--odd-card-bg);
}
.structured_sidebar_element:nth-child(odd) a {
	color: var(--odd-card-link);
}
.structured_sidebar_element:nth-child(odd) a::selection {
	background-color: var(--odd-card-link);
	color: var(--odd-card-bg);
}
.structured_sidebar_element:nth-child(odd) a:hover::selection {
	color: var(--even-card-link);
}
.structured_sidebar_element:nth-child(even) {
	background-color: var(--even-card-bg);
}
.structured_sidebar_element:nth-child(even) a {
	color: var(--even-card-link);
}
.structured_sidebar_element:nth-child(even) a::selection {
	background-color: var(--even-card-link);
	color: var(--even-card-bg);
}
.structured_sidebar_element:nth-child(even) a:hover::selection {
	color: var(--odd-card-bg);
}

.sse_item.title {
	font-weight: bold;
}

.sse_item.link, .break_all {
	word-break: break-all;
}

.warning_banner[data-show="true"] a {
	color: var(--tertiary-color);
	border-bottom: 2px dashed var(--primary-color);
}
.warning_banner[data-show="true"] a::selection {
	background-color: var(--tertiary-color);
	color: var(--primary-color);
}
.warning_banner[data-show="true"] a:hover::selection {
	color: var(--text-color);
}

.warning_banner_text_wrapper {
	display: flex;
	align-items: center;
}

.warning_banner_text {
	display: block;
}

.align_left {
	text-align: left;
}


.notification {
	padding: 10px;
	margin: 15px;
}

.notification_green {
	background-color: var(--senary-color);
	color: var(--text-color);
}
.notification_green > p::selection,
.notification_green > h5::selection {
	color: var(--senary-color);
	background-color: var(--text-color);
}

.notification_blue {
	background-color: var(--septary-color);
	color: var(--text-color);
}
.notification_blue > p::selection,
.notification_blue > h5::selection {
	color: var(--septary-color);
	background-color: var(--text-color);
}
.notification_blue a:not(.aexempt) {
	color: var(--even-card-link);
}

.notification_pink {
	background-color: var(--quaternary-color);
	color: var(--text-color);
}
.notification_pink a:not(.aexempt) {
	color: #14CCD6;
}
.notification_pink a:not(.aexempt)::selection {
	color: var(--primary-color);
	background-color: #14CCD6;
}
.notification_pink > p::selection,
.notification_pink > h5::selection {
	color: var(--quaternary-color);
	background-color: var(--text-color);
}

.medal_list_button {
	--border-size: 5px;
	--border-color: var(--odd-card-link);
	background-color: var(--senary-color);
	color: var(--light-color);
	padding: 10px;
	border-radius: 5px;
	user-select: none;
	cursor: pointer;
	border-right: var(--border-size) solid var(--border-color);
	border-bottom: var(--border-size) solid var(--border-color);
	transition: border-width 200ms linear;
}
.medal_list_button:hover {
	border-width: 1px;
}

.notification > p {
	font-size: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.notification > h5 {
	margin-top: 5px;
	margin-bottom: 0;
	font-size: 20px;
}

/* .hour_requirement > h3 {
	margin-bottom: 0;
}

.hour_requirement > p {
	margin-top: 0;
} */

.hour_requirement {
	background: linear-gradient(to bottom right, #333, #555, #888);
	color: var(--secondary-color);
	box-shadow: 5px 5px 10px var(--secondary-color);
	border: 2px solid var(--secondary-color);
	width: 350px;
	padding: 10px;
	border-radius: 40px;
	margin: 20px auto;
}

.hour_requirement > * {
	margin: 20px 0 !important;
}

.hour_requirement > h3 {
	font-size: large;
	font-weight: normal;
	color: var(--even-card-link);
}

.hour_requirement #hr_req {
	font-size: xx-large;
	font-weight: bold;
	color: var(--odd-card-link);
	font-family: Arial, Helvetica, sans-serif;
}

.hour_requirement #org_req {
	font-size: normal;
	font-weight: normal;
}

.faq_item {
	margin: 20px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: var(--dark-color);
	border-radius: 10px;
	padding-bottom: 5px;
}

.faq_item > summary {
	padding: 5px;
	list-style: none;
}

details > summary {
	user-select: none;
	cursor: pointer;
}

details summary > * {
	display: inline;
}

.contract_link {
	border-bottom: 2px dashed transparent;
	color: var(--septary-color);
}

.contract_link:hover {
	border-bottom: 2px dashed var(--senary-color);
}

.download_contract_link {
	transition: color 300ms;
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit;
}

.download_contract_link:hover {
	color: var(--senary-color);
}

header {
	display: flex;
	justify-content: space-between;
}

.header_left {
	display: flex;
	align-items: center;
	justify-content: space-between;
	vertical-align: middle;
}

.menu_icon {
	vertical-align: middle;
	margin-right: 15px;
}

.header_text {
	display: flex;
	flex-direction: column;
	padding: 6px;
}

.header_title {
	font-size: x-large;
}

.header_description {
	font-size: small;
}

.header_right {
	display: flex;
	align-items: center;
}

.header_link {
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-left: 10px;
	margin-right: 10px;
	border-radius: 10px;
	background-color: var(--quaternary-color);
	color: var(--text-color);
	text-decoration: none;
	transition: background-color 250ms;
}

.header_link:hover {
	color: var(--quaternary-color);
	background-color: var(--text-color);
}
.header_link::selection {
	color: var(--quaternary-color);
	background-color: var(--text-color);
}
.header_link:hover::selection {
	background-color: var(--quaternary-color);
	color: var(--text-color);
}

a {
	cursor: pointer;
}

footer {
	min-height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: small;
	padding: 10px;
}

.modal {
	position: absolute;
	border-radius: 20px;
	background-color: var(--background-color);
  	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	box-shadow: var(--tertiary-color) 5px 5px 15px;
	display: none;
}

#modal_about {
	width: 300px;
	height: 300px;
	padding: 20px;
}

.modal p {
	padding: 10px;
	text-align: center;
}

.modal_header {
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.close_modal {
	cursor: pointer;
	transition: background-color 300ms;
}

.close_modal:hover {
	background-color: var(--warning-color);
}

.content404 hr {
	width: 100%;
}

.content404 .downloadable_form, .content404 .about_link, .content404 .url404 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.downloadable_form, .about_link, .url404 {
	user-select: none;
	background-color: var(--tertiary-color);
	width: 90%;
	color: var(--dark-color);
	padding: 10px;
	border-radius: 10px;
	box-shadow: var(--tertiary-color) 0px 2px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 250ms;
}

.form_name, .link_name, .url404_name {
	color: inherit;
	text-decoration: none;
}

.downloadable_form:hover, .about_link:hover, .url404:hover {
	background-color: var(--senary-color);
	color: var(--text-color);
}

.form_container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.download_icon, .link_icon, .url404_icon {
	transition: color 250ms;
}

.download_icon:hover, .link_icon:hover, .url404_icon:hover {
	color: var(--quinary-color);
}

.header_half {
	width: 50%;
}

.aexempt {
	text-decoration: none;
}

a:not(.aexempt) {
	text-decoration: none;
	border-bottom: 2px solid transparent;
	transition: color 200ms;
	color: var(--link-color);
}
a:not(.aexempt):hover {
	border-bottom: 2px dashed var(--septary-color);
	color: var(--link-hover-color);
}

.large_warning {
	font-size: xx-large;
	color: var(--warning-color);
}

.text_center {
	text-align: center;
}

.width_100 {
	width: 100%;
}

#analytics_ignore {
	display: none;
	background-color: var(--septary-color);
	color: var(--light-color);
	border-radius: 8px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 10px;
	border-radius: 10px;
}

@media print {
	p { page-break-before: always; }
	.hide_on_print {
		display: none;
	}
	.maincontent:not(.hide_on_print) {
		display: inline-block;
		width: 100%;
		height: 100%;;
		overflow: visible;
	}
	body, html {
		height: 100%;
		display: inline-block;
	}
	footer {
		display: none;
	}
	#analytics_ignore {
		display: none !important;
	}
}

@media not print {
	.hide_on_not_print {
		display: none;
	}
	.maincontent {
		overflow-y: auto;
	}
}

#medal_list_grid {
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(auto-fit, minmax(min-content, 200px));
}

.medal_list_item {
	--border-size: 5px;
	--border-color: var(--odd-card-link);
	background-color: var(--quaternary-color);
	margin: 10px 10px;
	padding: 10px;
	border-radius: 5px;
	user-select: none;
	cursor: pointer;
	border-right: var(--border-size) solid var(--border-color);
	border-bottom: var(--border-size) solid var(--border-color);
	transition: border-width 200ms linear;
}

.medal_list_item:hover {
	border-width: 1px;
}

#medal_list_marquee {
	overflow: hidden;
	clear: both;
}

#medal_list_name {
	font-size: large;
	font-weight: bold;
	color: var(--odd-card-link);
}

#copied_dialog {
	background-color: var(--senary-color);
	color: var(--light-color);
	width: 270px;
}
#error_dialog {
	background-color: var(--quaternary-color);
	color: var(--light-color);
	width: 440px;
}
.popup_dialog {
	position: fixed;
	opacity: 0;
	right: 10px;
	bottom: 10px;
	padding: 15px;
	font-family: 'Lucida Sans';
	font-weight: bold;
	font-size: large;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
	transition: opacity 200ms ease;
}